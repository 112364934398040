import React, { useState } from "react";
import AppDatabase from "../storage/AppDatabase";
import DatabaseProviderContext from "./DatabaseProviderContext";

interface Props {
  children: React.ReactNode;
}

const StorageProvider = ({ children }: Props) => {
  const [db] = useState(new AppDatabase());
  return (
    <DatabaseProviderContext.Provider value={db}>
      {children}
    </DatabaseProviderContext.Provider>
  );
};

export default StorageProvider;
