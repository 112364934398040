import { CssBaseline, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import AppRenderer from "./AppRenderer";
import GlobalContextsProvider from "./GlobalContextsProvider";
import { Provider } from "react-redux";
import store from "./state/store";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  const darkTheme = responsiveFontSizes(
    createTheme({
      palette: {
        mode: "dark",
      },
    })
  );

  return (
    <div data-testid="app-container-testid">
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <GlobalContextsProvider>
              <Typography>Duun is coming soon!</Typography>
            </GlobalContextsProvider>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;
