import Dexie from "dexie";

type DexieNavigationState = string;

class AppDatabase extends Dexie {
  navigationState: Dexie.Table<DexieNavigationState, string>;

  constructor() {
    super("AppDatabase");

    this.version(1).stores({
      navigationState: "",
    });

    this.navigationState = this.table("navigationState");
  }
}

export default AppDatabase;
