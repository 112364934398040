import { cloneDeep } from "lodash";

const defaultAppNavigationState: AppNavigationState = {
  myBookings: {},
  booking: {
    activeStep: 0,
    what: null,
    where: {
      physicalLocation: true,
      location: null,
    },
    when: { type: "timeFrame", startTime: null, endTime: null },
    more: {
      title: "",
      description: "",
      budget: null,
    },
  },
  account: {},
};

const appNavigationStateReducer = (
  state: NavigationState["app"] = cloneDeep(defaultAppNavigationState),
  action: AppNavigationStateAction
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case "UPDATE_ACTIVE_BOOKING_STEP": {
      const { direction } = action.data;
      newState.booking.activeStep = Math.max(
        Math.min(
          newState.booking.activeStep + (direction === "next" ? 1 : -1),
          4
        ),
        0
      );
      return newState;
    }
    case "UPDATE_BOOKING_WHAT": {
      const { value } = action.data;
      newState.booking.what = value;
      return newState;
    }
    case "UPDATE_BOOKING_WHERE": {
      const { value } = action.data;
      newState.booking.where = value;
      return newState;
    }
    case "UPDATE_BOOKING_WHEN": {
      const { value } = action.data;
      newState.booking.when = value;
      return newState;
    }
    case "UPDATE_BOOKING_MORE": {
      const { value } = action.data;
      newState.booking.more = value;
      return newState;
    }
    case "RESET_APP_STATE": {
      return defaultAppNavigationState;
    }
    case "LOAD_APP_STATE": {
      const { appNavigationState } = action.data;
      return appNavigationState;
    }
    default: {
      return newState;
    }
  }
};

const resetAppState = (): ResetAppState => {
  return {
    type: "RESET_APP_STATE",
  };
};

const loadAppState = (appNavigationState: AppNavigationState): LoadAppState => {
  return {
    type: "LOAD_APP_STATE",
    data: {
      appNavigationState,
    },
  };
};

const updateActiveBookingStep = (
  direction: "next" | "back"
): UpdateActiveBookingStep => {
  return {
    type: "UPDATE_ACTIVE_BOOKING_STEP",
    data: {
      direction,
    },
  };
};

const updateBookingWhat = (value: BookingWhat): UpdateBookingWhat => {
  return {
    type: "UPDATE_BOOKING_WHAT",
    data: {
      value,
    },
  };
};

const updateBookingWhere = (value: BookingWhere): UpdateBookingWhere => {
  return {
    type: "UPDATE_BOOKING_WHERE",
    data: {
      value,
    },
  };
};

const updateBookingWhen = (value: BookingWhen): UpdateBookingWhen => {
  return {
    type: "UPDATE_BOOKING_WHEN",
    data: {
      value,
    },
  };
};

const updateBookingMore = (value: BookingMore): UpdateBookingMore => {
  return {
    type: "UPDATE_BOOKING_MORE",
    data: {
      value,
    },
  };
};

export default appNavigationStateReducer;
export {
  defaultAppNavigationState,
  updateActiveBookingStep,
  updateBookingWhat,
  updateBookingWhere,
  updateBookingWhen,
  updateBookingMore,
  resetAppState,
  loadAppState,
};
