import AppDatabase from "./AppDatabase";

interface Params {
  db: AppDatabase;
  uid: string;
}

class LocalUserNavigationStateStorage
  implements LocalUserNavigationStateStorage
{
  db: AppDatabase;

  uid: string;

  constructor({ db, uid }: Params) {
    this.db = db;
    this.uid = uid;
  }

  async getNavigationState() {
    const savedNavigationState = await this.db.navigationState.get(this.uid);
    return typeof savedNavigationState === "string"
      ? (JSON.parse(savedNavigationState) as NavigationState)
      : undefined;
  }

  async setNavigationState(navigationState: NavigationState) {
    const stringifiedNavigationState = JSON.stringify(navigationState);
    await this.db.navigationState.put(stringifiedNavigationState, this.uid);
  }
}

export default LocalUserNavigationStateStorage;
