import { debounce } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatabaseProviderContext from "./contexts/DatabaseProviderContext";
import LayoutInformationContextProvider from "./contexts/LayoutInformationContext";
import { loadAppState } from "./state/appNavigationStateReducer";
import LocalUserNavigationStateStorage from "./storage/LocalAppNavigationStateStorage";

interface Props {
  children: React.ReactNode;
}

const GlobalContextsProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const db = useContext(DatabaseProviderContext);
  const uid = "loggedOutUser"; // TODO
  const localUserNavigationStateStorage = useMemo(() => {
    return uid ? new LocalUserNavigationStateStorage({ db, uid }) : undefined;
  }, [uid, db]);
  const localUserNavigationState = useSelector<
    NavigationState,
    NavigationState
  >((state) => state);

  const debouncedNavigationStateSave = useMemo(
    () =>
      debounce((navState: NavigationState) => {
        if (localUserNavigationStateStorage) {
          localUserNavigationStateStorage
            .setNavigationState(navState)
            .catch(() => {});
        }
      }, 1 * 1000),
    [localUserNavigationStateStorage]
  );

  useEffect(() => {
    debouncedNavigationStateSave(localUserNavigationState);
  }, [debouncedNavigationStateSave, localUserNavigationState]);

  useEffect(() => {
    if (localUserNavigationStateStorage) {
      const loadInitialNavigationState = async () => {
        const navigationState =
          await localUserNavigationStateStorage.getNavigationState();
        if (navigationState) {
          dispatch(loadAppState(navigationState.app));
        }
      };

      loadInitialNavigationState();
    }
  }, [dispatch, localUserNavigationStateStorage]);

  return (
    <LayoutInformationContextProvider>
      {children}
    </LayoutInformationContextProvider>
  );
};

export default GlobalContextsProvider;
