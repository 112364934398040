import { useMediaQuery, useTheme } from "@mui/material";
import { createContext, memo } from "react";

interface LayoutInformationContextValue {
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
}

export const defaultLayoutInformationContextValue = {
  isSmallScreen: false,
  isMediumScreen: false,
  isLargeScreen: false,
};

export const LayoutInformationContext =
  createContext<LayoutInformationContextValue>(
    defaultLayoutInformationContextValue
  );

interface Props {
  children: React.ReactNode;
}

const LayoutInformationContextProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen =
    useMediaQuery(theme.breakpoints.up("sm")) && !isLargeScreen;
  const isSmallScreen =
    useMediaQuery(theme.breakpoints.up("xs")) &&
    !isLargeScreen &&
    !isMediumScreen;

  const isBreakpointCalculated =
    isSmallScreen || isMediumScreen || isLargeScreen;

  return isBreakpointCalculated ? (
    <LayoutInformationContext.Provider
      value={{ isSmallScreen, isMediumScreen, isLargeScreen }}
    >
      {children}
    </LayoutInformationContext.Provider>
  ) : null;
};

export default memo(LayoutInformationContextProvider);
