import { createStore, combineReducers } from "redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension";
import appNavigationStateReducer from "./appNavigationStateReducer";

const rootReducer = combineReducers({
  app: appNavigationStateReducer,
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
